<template>
  <v-sheet>
    Obchod
  </v-sheet>
</template>
<script>

  export default {

    data () {
      return {
        
      }
    },
  }
</script>