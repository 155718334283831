<template>
    <v-card rounded="lg" class="mx-auto px-5 pb-3 pt-2" width="80%">
        <div v-if="edit" class="mx-2 mt-2">
            <v-text-field class="pt-5 mb-0" v-model="postData.title" label="Zadejte název příspěvku" solo></v-text-field>

            <v-container fluid class="py-0 pb-2">
                <v-row justify="center" align="center">

                    <v-col cols="3">
                        <v-layout>
                            <v-flex>
                                <v-btn fab class="white--black mr-4 mt-2" color="info" small @click="dialogCategory = true">
                                    <v-icon color="black" small>fa-solid fa-pen-to-square</v-icon>
                                </v-btn>
                            </v-flex>

                            <v-flex>
                                <v-select hide-details class="py-2" label="Kategorie" dense solo :items="postCategories" item-text="category_name" v-model="postData.categoryName"></v-select>
                            </v-flex>
                        </v-layout>
                    </v-col>

                    <v-col cols="9">
                        <v-layout row wrap justify-end>

                            <v-chip class="text-uppercase font-weight-medium black--text" label :color="postData.important ? 'error' : ''" @click="postData.important = !postData.important">
                                <v-avatar left>
                                    <v-icon small>fa-solid fa-triangle-exclamation</v-icon>
                                </v-avatar>
                                důležitý
                            </v-chip>
                            
                            <v-chip v-if="isNew(postData.modified)" color="success" class="ml-5 text-uppercase font-weight-medium black--text" label>
                                <v-avatar left>
                                    <v-icon small>fa-solid fa-star</v-icon>
                                </v-avatar>
                                nový
                            </v-chip>

                        </v-layout>
                    </v-col>

                </v-row>
            </v-container>

            <v-dialog v-model="dialogCategory" max-width="500px">
                <v-card>
                    <v-card-title>Kategorie příspěvků</v-card-title>
                    
                    <v-card-text>

                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                    <th class="text-left text-uppercase">
                                        Kategorie
                                    </th>
                                    <th class="text-center text-uppercase">
                                        Akce
                                    </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in postCategories" :key="item.id">
                                        <td v-if="item.id == categoryData.id"><v-text-field v-model="categoryData.category_name" outlined hide-details></v-text-field></td>
                                        <td v-else class="text-uppercase font-weight-medium">{{ item.category_name }}</td>
                                        <td class="text-center">

                                            <v-btn v-if="item.id == categoryData.id" fab class="white--text mr-4" color="success" x-small @click="updateCategory()">
                                                <v-icon small>fa-solid fa-floppy-disk</v-icon>
                                            </v-btn>

                                            <v-btn fab class="white--text mr-2" color="info" x-small @click="editCategory(item.id, item.category_name)">
                                                <v-icon small>fa-solid fa-pen-to-square</v-icon>
                                            </v-btn>
                                            
                                            <v-btn fab class="white--text ml-2" color="error" x-small @click="deleteCategory(item.id, item.category_name)">
                                                <v-icon small>fa-solid fa-trash-can</v-icon>
                                            </v-btn>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>

                        <v-alert v-if="showAlert" class="mt-4" border="right" colored-border type="error" elevation="2" @click="showAlert = !showAlert">
                            Kategorii "{{alertedCategory}}" nelze odstranit, protože je přiřazena k příspěvku!
                        </v-alert>

                        <v-divider></v-divider>

                        <v-layout v-if="addCategory" class="mt-5 text-center">
                            <v-flex>
                                <v-text-field v-model="categoryData.category_name" outlined hide-details></v-text-field>
                            </v-flex>

                            <v-flex>
                                <v-btn fab class="white--text ml-4" color="success" @click="createCategory()">
                                    <v-icon>fa-solid fa-floppy-disk</v-icon>
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-card-text>
          
                    <v-card-actions class="justify-end">
                        <v-btn class="mr-4" color="success" @click="addCategory = !addCategory">{{ addCategory ? 'skrýt' : 'přidat'}}</v-btn>
                        <v-btn color="primary" @click="dialogCategory = false">zavřít</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <vue-2-editor class="mt-5" :propContent = postData.htmlContent @changeContent = "changeContent"></vue-2-editor>
        </div>

        <div v-else>
            <web-post :postData = postData></web-post>
        </div>

        <v-card-actions class="justify-end">
            <v-btn class="black--text" color="warning" @click="edit = !edit">
                <v-icon v-if="edit" class="mr-2">fa-sharp fa-solid fa-magnifying-glass</v-icon>
                <v-icon v-else class="mr-2">fa-solid fa-pen-to-square</v-icon>
                {{ edit ? 'náhled' : 'editace'}}
            </v-btn>
            <v-btn class="black--text" color="success" @click="savePost"><v-icon class="mr-2">fa-solid fa-floppy-disk</v-icon>uložit</v-btn>
        </v-card-actions>

    </v-card>
</template>
<script>
import WebPost from '../../components/WebPost';
import Vue2Editor from '../../components/Vue2Editor';

//import file from '../../assets/posts/minecraft/PostWithID27.html'

export default {
    props: {
        post: null
    },
    components: {
        WebPost,
        Vue2Editor
    },
    data () {
        return {
            postData: {
                postId: -1,
                author: 'krystoflinek',
                edited: "",
                htmlContent: '',
                pathHtmlContent: '',
                title: "Tohle je můj nadpis2",
                categoryId: 1,
                categoryName: '',
            },
            postCategories: [],

            categoryData: {
                id: -1,
                category_name: ''
            },
            addCategory: false,

            showAlert: false,
            alertedCategory: '',

            edit: false,
            dialogCategory: false,
        }
    },
    watch: {
        edit: function(newValue) {
            if (newValue)
                this.loadAllCategories()
        }
    },
    methods: {
        async loadPostById(){
            try {
                const response =  await this.$http.get(`/post/${this.$route.params.id_post}`);

                this.postData = this.formatPost(response.data)

            } catch(e){
                console.log(e)
            }
        },
        async loadAllCategories(){
            try {
                const response =  await this.$http.get(`/post/category/list`);

                this.postCategories = response.data;

            } catch(e){
                console.log(e)
            }
        },
        async createCategory(){
            try{
                let formData = new FormData();

                formData.append('categoryName', this.categoryData.category_name);

                const response = await this.$http.post("/post/category/create", formData);

                if (response.status == 200)
                    this.loadAllCategories()

                this.categoryData.id = -1;
                this.categoryData.category_name = '';

                this.addCategory = false;
      
            } catch(e){
                console.log(e)
            }
        },
        async updateCategory(){
            try{
                const response = await this.$http.post("/post/category/update", {id: this.categoryData.id, category_name: this.categoryData.category_name});

                if (response.status == 200)
                    this.loadAllCategories()

                this.categoryData.id = -1;
                this.categoryData.category_name = '';
      
            } catch(e){
                console.log(e)
            }
        },
        async deleteCategory(id, name){
            try{
                const response = await this.$http.post("/post/category/delete", {id: id, category_name: name});

                if (response.status == 204)
                    this.loadAllCategories()
      
            } catch(e){
                if (e.response.status == 409){
                    this.alertedCategory = name;
                    this.showAlert = true;
                }
            }
        },
        async savePost(){

            let formData = new FormData();

            formData.append('author', this.postData.author);
            formData.append('htmlContent', this.postData.htmlContent);
            formData.append('title', this.postData.title);
            formData.append('categoryId', this.postData.categoryId);

            try {
                const response = await this.$http.post("/post/create", formData, 
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                    console.log(response.data)
                    this.setPostData(response.data)

            } catch (error) {
                console.error(error);
            }
        },
        changeContent(content){
            this.postData.htmlContent = content
        },
        formatPost(post){
            let postData = {
                title: post.title,
                htmlContent: post.htmlContent,
                categoryName: post.category.category_name,
                author: post.author,
                edited: post.edited,
                created: post.created,
                modified: post.modified,
                important: post.important,
            };

            return postData
        },
        isNew(modified) {
            let now = Date.now();
            let diffTime = Math.abs(now - modified);
            let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

            return diffDays <= 3 ? true : false
        },
        setPostData(newData){
            this.postData.postId = newData.postId;
            this.postData.author = newData.author;
            this.postData.edited = newData.edited;
            this.postData.htmlContent = newData.pathHtmlContent;
            this.postData.title = newData.title;
            this.postData.categoryId = newData.category.id;
            this.postData.categoryName = newData.category.category_name;
            this.postData.pathHtmlContent = newData.pathHtmlContent;

            this.loadPost();
        },
        editCategory(id, name){
            if (this.categoryData.id == id){
                this.categoryData.id = -1;
                this.categoryData.category_name = '';
            }
            else {
                this.categoryData.id = id;
                this.categoryData.category_name = name;
            }
        },
    },
    mounted(){
        if (this.post == null)
            this.loadPostById()
        else
            this.postData = this.post
            this.edit = this.propEdit
    }
  }
</script>