<template>
    <v-container>
        <v-row>
            <v-col cols="10" sm="9" xl="10">
     
                <v-card rounded="lg" class="mb-6 three-lines" hidden v-for="post in posts" :key="post.postId" @mouseover="hoveredPost = post.postId" @mouseleave="hoveredPost = -1" 
                        @click="$router.push({ name: 'webPostPage', params: {id_post: post.postId, post: formatPost(post)} })"> 
          
                    <web-post :postData = formatPost(post)></web-post>
      
                    <v-card-actions class="justify-end" v-if="hoveredPost == post.postId">
                        <v-btn color="black" text><v-icon class="mr-1">fa-solid fa-trash-can</v-icon>smazat</v-btn>
                        <v-btn color="black" text><v-icon class="mr-1">fa-solid fa-share-from-square</v-icon>sdílet</v-btn>
                        <v-btn color="black" text @click="$router.push({ name: 'webPostPage', params: {postTitle: post.title, postText: post.text, propEdit: true} })">
                            <v-icon class="mr-1">fa-solid fa-pen-to-square</v-icon>upravit
                        </v-btn>
                    </v-card-actions>
                </v-card> 
            </v-col>
    
            <v-col cols="2" sm="3" xl="2"> 
                <v-card rounded="lg" width="100%">
                    <v-card-title class="justify-center">Kategorie příspěvků</v-card-title>

                    <v-card-text>
                        <v-layout column>
                            <v-btn :color="activeCategory == -1 ? 'warning' : ''" @click="activeCategory = -1">
                                aktuální
                            </v-btn>
                            <v-btn class="mt-1" :color="activeCategory == category.id ? 'warning' : ''" v-for="category in categoryList" :key="category.id" @click="activeCategory = category.id">
                                {{ category.category_name }}
                            </v-btn>
                        </v-layout>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import WebPost from '../../components/WebPost';

export default {
    components: {
        WebPost,
    },
    data () {
        return {
            hoveredPost: -1,
            posts: [],
            postsToLoad: 10,

            categoryList: [],
            activeCategory: -1,
        }
    },
    watch: {
        activeCategory: function(newValue) {
            if (newValue == -1)
                this.loadLastPosts()
            else
                this.loadPostsByCategory();
        }
    },
    methods: {
        async loadLastPosts(){
            try {
            const response =  await this.$http.get(`/post/lasts/${this.postsToLoad}`);

            this.posts = response.data;

            } catch(e){
                console.log(e)
            }
        },
        async loadPostsByCategory(){
            try {
            const response =  await this.$http.get(`/post/${this.postsToLoad}/category/${this.activeCategory}`);

            this.posts = response.data;

            } catch(e){
                console.log(e)
            }
        },
        async loadAllCategories(){
            try {
                const response =  await this.$http.get(`/post/category/list`);

                this.categoryList = response.data;

            } catch(e){
                console.log(e)
            }
        },
        formatPost(post){
            let postData = {
                title: post.title,
                htmlContent: post.htmlContent,
                categoryName: post.category.category_name,
                author: post.author,
                edited: post.edited,
                created: post.created,
                modified: post.modified,
                important: post.important,
            };

            return postData
        },
    },
    mounted(){
        this.loadAllCategories();
    
        if (this.activeCategory == -1)
            this.loadLastPosts()
        else
            this.loadPostsByCategory();
    }
}
</script>